import React from "react"
import {
  FormControl,
  Input,
  Flex,
  Box,
  Button,
  Text,
  Textarea,
} from "@chakra-ui/react"

const ContactForm = ({ content }) => {
  return (
    <>
      <Flex width="full" align="center" justifyContent="center">
        <Box p={2} width="xl">
          <Box>
            <Text>{content}</Text>
          </Box>
          <Box my={4} textAlign="left">
            <form
              method="POST"
              data-netlify="true"
              onSubmit="submit"
              name="contact v1"
            >
              <input type="hidden" name="form-name" value="contact v1" />
              <FormControl mt={6}>
                <Input
                  type="text"
                  name="name-geo"
                  placeholder="Name (required)"
                  required
                />
              </FormControl>
              <FormControl mt={6}>
                <Input
                  type="email"
                  name="email-geo"
                  placeholder="Email Address (required)"
                  required
                />
              </FormControl>
              <FormControl mt={6}>
                <Input
                  type="text"
                  name="phone-geo"
                  placeholder="Phone Number"
                />
              </FormControl>
              <FormControl mt={6}>
                <Input
                  type="text"
                  name="companyname-geo"
                  placeholder="Company Name"
                />
              </FormControl>
              <FormControl mt={12}>
                <Input type="text" name="subject-geo" placeholder="Subject" />
              </FormControl>
              <FormControl mt={6}>
                <Textarea
                  type="message"
                  name="message_geo"
                  placeholder="Message (required)"
                  required
                />
              </FormControl>
              <Button width="full" mt={5} type="submit">
                Send
              </Button>
            </form>
          </Box>
        </Box>
      </Flex>
    </>
  )
}

export default ContactForm

import React from "react";
import {
  Box,
  Flex,
  useColorModeValue,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react";

const Banner = ({ title }) => (
  <Box as="section" bgGradient="linear(to-r, blue.200, #ebd9fc)">
    <Box maxW="3xl" mx="auto" px={{ base: "6", md: "8" }} pt="12" pb="16">
      <Flex direction="column" align="center" textAlign="center">
        <Heading
          as="h1"
          size="4xl"
          fontSize={useBreakpointValue({
            base: "5xl",
            md: "7xl",
          })}
        >
          {title}
        </Heading>
      </Flex>
    </Box>
  </Box>
);

export default Banner;

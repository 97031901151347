import React from "react";
import { Box, Container, Heading, Text, Flex, Image } from "@chakra-ui/react";

const Embed = ({ url, title, description }) => {
  return (
    <Flex
      my={6}
      bgGradient="linear(to-r, blue.200, #ebd9fc)"
      display={{ md: "flex" }}
    >
      <Image
        boxSize={{ base: "50%", sm: "100%", md: "50%" }}
        objectFit="cover"
        src={url}
      />
      <Box p={6} boxSize={{ base: "50%", sm: "100%", md: "50%" }}>
        <Heading as="h4" size="lg" pb={4}>
          {title}
        </Heading>
        <Text fontSize="lg">{description}</Text>
      </Box>
    </Flex>
  );
};

export default Embed;

import React from "react";
import { graphql } from "gatsby";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Header from "./header-template";
import Banner from "./banner-template";
import Embed from "./embed-template";
import Footer from "./footer-template";
import { Box, Container, Heading, Text, Link } from "@chakra-ui/react";
import ContactForm from "./contact-template";

export const richTextContent = graphql`
  query ($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      slug
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            id
            title
            description
            file {
              url
            }
          }
        }
      }
    }
  }
`;

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Text fontWeight="bold">{text}</Text>,
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: ({ data }) => {
      return (
        <Embed
          url={data.target.file.url}
          title={data.target.title}
          description={data.target.description}
        />
      );
    },
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <Link href={data.uri} target="_blank" color="#145091">
        {children}
      </Link>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Text fontSize="lg" py="10px">
        {children}
      </Text>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li style={{ marginLeft: "40px" }}>{children}</li>
    ),
    [BLOCKS.HEADING_1]: (node, children) => (
      <Heading as="h1" size="2xl" py="0.8em">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Heading as="h2" size="xl" py="0.7em">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Heading as="h3" size="xl" py="0.6em">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Heading as="h4" size="lg" py="0.5em">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Heading as="h5" size="md" py="0.4em">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Heading as="h6" fontSize="18px" py="0.4em">
        {children}
      </Heading>
    ),
  },
};

const Page = ({ data: { page } }) => {
  const output = renderRichText(page.content, options);

  return (
    <div>
      <Header links={page.slug} />
      <Banner title={page.slug} />
      <Container maxW="container.lg" minHeight="calc(100vh - 70px)" py="40px">
        {page.slug !== "Contact Us" ? (
          page.content && output
        ) : (
          <ContactForm content={output} />
        )}
      </Container>
      <Box mt="50px" bg="#145091">
        <Footer notHome />
      </Box>
    </div>
  );
};

export default Page;
